<template>
  <div class="wrapper">
    <Section>
      <HeadingBlock :data="pageContent" />
    </Section>
    <Section>
      <TextBlock :text="pageContent.bodyText" />
    </Section>
  </div>
</template>

<script>
import Section from "@/components/Section/Section.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import TextBlock from "@/components/Blocks/TextBlock.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "Legal",
  metaInfo() {
    return manageSEO(this.seo, this.seo, false, this.dealerName);
  },
  components: {
    Section,
    HeadingBlock,
    TextBlock,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  computed: {
    pageContent() {
      return this.$store.state.legal;
    },
    seo() {
      return this.$store.state.homepage.seo;
    },
  },
};
</script>
